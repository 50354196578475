import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function LandingSlider() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      data-bs-theme="dark"
      controls={false}
    >
      <Carousel.Item>
        <img src="./assets/images/slider1.webp" alt="" className="w-100" />

        <Carousel.Caption>
          <h1 className="fw-bold ">વિશ્વ ની અવ્વલ પ્રજા એટલે પાટીદાર સમાજ </h1>
          <p className="h4 fw-bold ">
            આ પાટીદાર સમાજ નુ શિરમોર ગૌરવ એટલે છ ગામ પાટીદાર મંડળ. ભાદરણ, ધર્મજ,
            કરમસદ, નડિયાદ, સોજીત્રા, વસો અને સાવલી- આ છ ગામ ના પાટીદાર સમાજ ને
            જોડતું એક વિશ્વવ્યાપી ડિજિટલ પ્લેટફોર્મ.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default LandingSlider;
