import React from "react";

function IconTime(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill={props.fill}
      viewBox="0 0 359.286 359.286"
    >
      <path d="M179.643 359.286c99.043 0 179.643-80.6 179.643-179.643S278.687 0 179.643 0 0 80.6 0 179.643s80.6 179.643 179.643 179.643zm0-335.334c85.869 0 155.691 69.821 155.691 155.691s-69.821 155.691-155.691 155.691S23.952 265.513 23.952 179.643 93.774 23.952 179.643 23.952z"></path>
      <path d="M232.039 236.89c2.216 1.796 4.85 2.635 7.485 2.635a11.91 11.91 0 009.341-4.491c4.132-5.15 3.293-12.695-1.856-16.827l-55.39-44.312V83.834c0-6.587-5.389-11.976-11.976-11.976s-11.976 5.389-11.976 11.976v95.81c0 3.653 1.677 7.066 4.491 9.341z"></path>
    </svg>
  );
}

export default IconTime;
