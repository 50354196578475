import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
function Header() {
  const location = useLocation();
  const menuItem = [
    {
      label: 'Home',
      path: '/',
    },
    {
      label: 'Patrika',
      path: '/patrika',
    },
    {
      label: 'Contact Us',
      path: '#ContactUs',
      deactive: true,
    },
    {
      label: 'Privacy Policy',
      path: '/privacy_policy',
    },
    // {
    //   label: "Office Bearers",
    //   path: "/office_bearers",
    // },
    {
      label: 'Abhinandan Form',
      path: 'https://storage.strinix.com/cpm/patrika/form.pdf',
      target: '_blank',
    },
  ];
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="./assets/images/logo.png"
            alt=""
            height={90}
            className="me-4"
          />{' '}
          <img src="./assets/images/cpm_log2.png" alt="" height={90} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto">
            {menuItem.map((menu, index) => {
              return (
                <Nav.Link
                  href={menu.path}
                  className={`fw-bold me-lg-3 text-body ${
                    !menu.deactive
                      ? location.pathname === menu.path
                        ? 'active'
                        : ''
                      : 'deactive'
                  }`}
                  key={index}
                  target={menu.target ? menu.target : ''}
                >
                  {menu.label}
                </Nav.Link>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
