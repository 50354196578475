import PdfCard from "../components/Patrika/PdfCard";
import { patrikaData } from "../data";
function Patrika() {
  return (
    <div className="container py-3 py-lg-5">
      <div className="row">
        {patrikaData.map((item, i) => {
          return (
            <div className="col-6 col-sm-4 col-lg-3" key={i}>
              <PdfCard
                key={i}
                month={item.month}
                year={item.year}
                pdf={item.pdf}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Patrika;
