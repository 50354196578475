function LandingInfo() {
  const landingInfo = [
    {
      heading: "શૈક્ષણિક યોગદાન",
      des: "રાહત દરે નોટબુક્સ, ચોપડીઓ, જરુરિયાતમંદ વિદ્યાર્થિઓને ઉચ્ચ અભ્યાસ માટે લોન, કોલેજ ની ફી, ઉપરાંત ઉચ્ચ ગુણ મેળવનાર વિદ્યાર્થિઓને ઇનામ અને શિલ્ડ ધ્વારા બહુમાન.",
    },
    {
      heading: "આર્થિક યોગદાન",
      des: "આર્થિક પરિસ્થિતી નબળી હોય તેવા કુટુમ્બોને અનાજ સહાય, આર્થિક તકલીફમા હોય તેવા સિનીયર નાગરિક ને યાત્રા સહાય, મહિલાઓ સ્વાવલંબી અને પગભર બને તે માટે શિવવાના સંચા, બહેનોને રસોઈકળા, હુન્નર (Skill Development), ધરગથ્થુ ઉપચારો વગેરે માહિતી અને માર્ગદર્શન ધ્વારા મહિલાના ઉત્કર્ષ માટે યોગદાન. નોકરી ઇચ્છતા સમાજ ના યુવાન-યુવતિઓને સમાજમાથી જ ક્યા-ક્યા રોજગારી ની તકો ઉપલબ્ધ છે તે અંગે ની જાણકારી.",
    },
    {
      heading: "સામાજિક યોગદાન",
      des: "વૈવાહિક મેળાપ, સલાહ અને માર્ગદર્શન, સમાજના સભ્યોએ મેળવેલી મહત્વ ની સિદ્ધિયો અંગે જાણકારી, ખાસ કરીને યુવાનો કોઇ પણ પ્રકારના નશાથી દૂર રહે તે અંગેની જાગૃતિ, આરોગ્યલક્ષી સગવડોની ઉપલભતા અંગે જાણકારી, નવી આરોગ્ય સેવાઓ ઉભી કરવા ની જરૂરિયાત, તે માટે યોગદાન આપવું, અન્ય સમાજ ઉપયોગી કાર્ય વિશેની માહિતી.",
    },
  ];
  return (
    <div className="container py-5">
      <h2 className="fw-bold  text-center pb-3">તમામ ક્ષેત્રે યોગદાન</h2>
      <div className="row ">
        {landingInfo.map((item, i) => {
          return (
            <div className="col-12 p-3 bg-light rounded-2 mb-3 " key={i}>
              <h2 className="fw-bold h5 text-primary ">{item.heading}</h2>
              <p className="fw-semibold mb-0 ">{item.des}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LandingInfo;
