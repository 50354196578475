import React from "react";

function IconPdf(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 481.882 481.882"
      xmlSpace="preserve"
      width={props.width}
      height={props.height}
    >
      <path
        fill="#EBF0F3"
        d="M315.031 0H69.873c-6.024 0-11.445 4.819-11.445 11.445v458.993c0 6.024 4.819 11.445 11.445 11.445H412.01c6.024 0 11.445-4.819 11.445-11.445V108.424c0-3.012-1.205-6.024-3.012-7.831L322.861 3.012C320.452 1.205 318.042 0 315.031 0z"
      ></path>
      <path
        fill="#D5D6DB"
        d="M330.692 103.605h91.558c-.602-1.205-1.205-2.409-2.409-3.012L371.05 51.802l-48.189-48.79c-1.205-1.205-1.807-1.807-3.012-2.409V92.16c-.602 6.626 4.819 11.445 10.843 11.445z"
      ></path>
      <path
        fill="#E56353"
        d="M137.939 425.864c-8.433 0-15.059-4.819-18.071-7.831-10.24-12.047-10.24-22.287-8.433-28.913 1.205-6.024 4.819-11.445 10.24-15.059 7.831-6.024 29.515-21.082 61.44-34.334 16.866-30.72 28.311-55.417 36.141-75.294-13.252-25.299-18.071-45.779-19.275-56.019-1.205-8.433-.602-16.866 1.807-24.094 6.024-19.275 19.275-19.878 24.696-19.275 1.205 0 22.287 3.012 25.901 33.129 0 1.205 2.409 20.48-13.854 65.656 10.24 17.468 24.696 36.744 46.984 55.417 21.082 0 43.972 1.807 68.066 7.831 5.421 1.205 10.24 4.216 13.854 8.433 6.024 6.626 7.228 15.059 4.216 23.492 0 0-1.205 4.216-4.819 8.433-6.626 9.035-19.275 12.047-29.515 6.626-22.889-12.047-42.165-24.696-57.826-37.948-31.925 1.205-60.235 8.433-83.125 17.468-10.24 18.673-22.889 39.153-37.346 62.042-3.012 4.216-6.626 7.831-11.445 9.638-4.215-.001-7.227.602-9.636.602zm29.515-60.838c-18.071 9.638-30.72 18.071-35.539 22.287-1.807 1.807-3.614 3.614-3.614 5.421-1.205 4.216.602 8.433 4.819 13.252 0 0 3.614 3.012 7.228 1.807 1.205-.602 2.409-1.205 3.012-3.012 8.433-13.854 16.866-27.106 24.094-39.755zm140.348-28.913c10.842 7.228 22.889 15.059 36.141 21.685 2.409 1.205 5.421.602 7.228-1.807 1.807-2.409 2.409-4.216 2.409-4.216 1.205-3.012 1.205-4.216-.602-6.024-1.205-1.205-3.012-2.409-5.421-3.012-13.251-3.614-26.503-5.421-39.755-6.626zm-78.306-53.609c-6.024 13.854-13.252 29.515-22.889 47.586 16.264-4.819 33.732-9.035 53.007-10.842-12.047-12.65-22.287-25.3-30.118-36.744zm-6.023-101.196c-2.409 0-4.216 2.409-6.024 7.831-1.807 4.819-1.807 10.24-1.205 16.264 1.205 7.228 3.614 19.275 10.24 34.936 9.035-28.913 7.831-40.96 7.831-40.96-1.807-16.264-10.24-18.071-10.842-18.071z"
      ></path>
    </svg>
  );
}

export default IconPdf;
