import IconHome from "../../Icon/IconHome";
import IconMail from "../../Icon/IconMail";
import IconPhone from "../../Icon/IconPhone";
import IconTime from "./IconTime";

function LandingContact() {
  const contactInfo = [
    {
      icon: <IconHome width="24" height="24" fill="#333" />,
      label:
        "Akar-38, Smruti kunj Society,O/P Narnarayan Complex,O/P Ashwmegh House, Navrangpura",
    },
    {
      icon: <IconPhone width="24" height="24" fill="#333" />,
      label: "+91-79-26425667",
      href: "tel:+91-79-26425667",
    },
    {
      icon: <IconTime width="24" height="24" fill="#333" />,
      label: "3:00PM To 7:00PM",
    },
    {
      icon: <IconMail width="24" height="24" fill="#333" />,
      label: "chhgaam@yahoo.com",
      href: "mailto:chhgaam@yahoo.com",
    },
  ];
  return (
    <div className="py-4 py-lg-5 bg-light" id="ContactUs">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="fw-bold  text-center pb-3">Contact Us</h2>
          </div>
          {contactInfo.map((item, i) => {
            return (
              <div
                className="col-md-6 col-xl text-lg-center d-flex align-items-center mb-3 flex-sm-column"
                key={i}
              >
                <span className="p-3 border rounded-pill"> {item.icon}</span>
                <div>
                  {item.href ? (
                    <a
                      className="ms-3  ms-sm-0 mt-sm-3 text-body d-block"
                      href={item.href}
                    >
                      {item.label}
                    </a>
                  ) : (
                    <p className="ms-3  ms-sm-0 mt-sm-3 mb-0">{item.label}</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LandingContact;
