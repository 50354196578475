import LandingContact from "./Landing/LandingContact";

function Footer() {
  return (
    <>
      <LandingContact />
      <div className="bg-primary py-4 text-center text-white">
        Copyright © {new Date().getFullYear()} - All Rights Reserved.
      </div>
    </>
  );
}

export default Footer;
