function OfficeBearers() {
  return (
    <div className="container">
      <div className="row mb-4 mt-4">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <h3 className="fw-bold  text-center">છ ગામ પાટીદાર મંડળ, અમદાવાદ</h3>
          <h4 className="fw-bold  text-center">નિયુક્ત હોદ્દેદારો ૨૦૨૩-૨૪</h4>
          <br />
          {/* <table className="table">
            <thead className="thead-dark">ટ્રસ્ટીશ્રી</thead>
            <tr>
              <td>Hetal Patel</td>
              <td>Ahmedabad</td>
            </tr>
          </table> */}
          <table class="table table-sm">
            {/* <thead>
              <tr>
                <th scope="row">નામ</th>
                <th scope="col">ગામ</th>
              </tr>
            </thead> */}
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  ટ્રસ્ટીશ્રી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી કંચનભાઈ રાવજીભાઇ અમીન</td>
                <td>વસો</td>
              </tr>
              <tr>
                <td>શ્રી ભુપેન્દ્રભાઈ છોટાભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રી પંકજભાઈ રમણભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  પ્રમુખશ્રી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રીમતિ મીનાબેન અશોકભાઇ પટેલ</td>
                <td>ધર્મજ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  પૂર્વપ્રમુખશ્રી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ડૉ. શ્રી યતિનભાઈ જયકૃષ્ણભાઈ દેસાઇ</td>
                <td>નડીયાદ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  ઉપપ્રમુખશ્રી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી કૌશિકભાઈ રતિભાઈ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
              <tr>
                <td>શ્રી વિપિનભાઈ કાંતિભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રી કીરીટભાઈ બાબુભાઇ અમીન </td>
                <td>વસો</td>
              </tr>
              <tr>
                <td>શ્રી અજયભાઈ જગુભાઈ પટેલ</td>
                <td>કરમસદ</td>
              </tr>
              <tr>
                <td>શ્રી આગ્નેય અરવિંદભાઇ દેસાઇ</td>
                <td>નડીઆદ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  મંત્રીશ્રી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી કૌશિકભાઈ શકુભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  સહમંત્રીશ્રી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી ધર્મેશભાઈ ચંદ્રકાંતભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રી ગીતેશભાઈ ચંદ્રકાંતભાઈ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
              <tr>
                <td>શ્રી નિખિલભાઈ જશુભાઇ પટેલ</td>
                <td>ધર્મજ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  ખજાનચી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી બકુલેશભાઈ હરિવદનભાઈ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  સહખજાનચી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી યોગેશભાઈ બચુભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રી પ્રકાશભાઈ ભગુભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  સંકલન સમિતિ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી પ્રશાંત સુરેશભાઇ પટેલ</td>
                <td>નડીઆદ</td>
              </tr>
              <tr>
                <td>શ્રી હિતેશભાઇ કનુભાઈ પટેલ</td>
                <td>નડીઆદ</td>
              </tr>
              <tr>
                <td>શ્રી દિલીપભાઇ મનુભાઈ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  ઓફિસ ઇન્ચાર્જ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી કિરીટભાઇ લલિતભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રી અરૂણભાઈ રાવજીભાઇ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
            </tbody>
          </table>

          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  કારોબારી સમિતિ, ભાદરણ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી રજનીકાંતભાઈ રામભાઇ પટેલ</td>
                <td>શ્રી યતિનભાઈ પ્રમુખભાઇ પટેલ</td>
              </tr>
              <tr>
                <td>શ્રી શિરીષભાઈ ઉપેન્દ્રભાઈ પટેલ</td>
                <td>શ્રી બંકિમભાઈ ઉપેન્દ્રભાઈ પટેલ</td>
              </tr>
              <tr>
                <td>શ્રી કમલભાઈ દિનુભાઈ પટેલ</td>
                <td>શ્રીમતિ શ્રીતિબેન યતિનભાઈ પટેલ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  કારોબારી સમિતિ, ધર્મજ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી જયેશભાઇ કાંતિભાઇ પટેલ</td>
                <td>શ્રી જગદીશભાઇ મનુભાઈ પટેલ</td>
              </tr>
              <tr>
                <td>શ્રી ભરતભાઇ મનુભાઈ પટેલ</td>
                <td>શ્રી અનંતભાઈ મનુભાઈ પટેલ</td>
              </tr>
              <tr>
                <td>શ્રી રૂષિક ભાસ્કરભાઈ પટેલ</td>
                <td>શ્રીમતિ સોનલબેન દિવ્યાંગભાઈ પટેલ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  કારોબારી સમિતિ, કરમસદ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી જૈમિનભાઈ નટુભાઇ પટેલ</td>
                <td>શ્રી હિમાંશુભાઈ ચંદ્રકાંતભાઈ પટેલ</td>
              </tr>
              <tr>
                <td>શ્રી વિનોદભાઇ રામભાઇ પટેલ</td>
                <td>શ્રી પ્રકાશભાઈ રમણભાઈ પટેલ</td>
              </tr>
              <tr>
                <td>શ્રી પાર્થકુમાર ઠાકોરભાઈ પટેલ</td>
                <td>શ્રીમતિ જીજ્ઞાસાબેન પ્રકાશભાઈ પટેલ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  કારોબારી સમિતિ, નડીઆદ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી રમણીકભાઇ હિરાભાઈ પટેલ</td>
                <td>શ્રી દીપકભાઈ ચંદુભાઇ પટેલ</td>
              </tr>
              <tr>
                <td>શ્રી અમરિષભઇ મધુભાઈ પટેલ</td>
                <td>શ્રી તેજસભાઈ પિયુષભાઈ પટેલ</td>
              </tr>
              <tr>
                <td>શ્રી યોગેશભાઇ ચંદુભાઇ પટેલ</td>
                <td>શ્રીમતિ બેલાબેન અમરિષભાઇ પટેલ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  કારોબારી સમિતિ, સોજીત્રા
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી સમીરભાઈ નલિનકાન્ત પટેલ</td>
                <td>શ્રી હેતલભાઇ ભુપેન્દ્રભાઈ પટેલ</td>
              </tr>
              <tr>
                <td>શ્રી ભરતભાઇ જયરામભાઇ પટેલ</td>
                <td>શ્રી મિહિરભાઈ યશવંતભાઈ પટેલ</td>
              </tr>
              <tr>
                <td>શ્રી રાકેશભાઈ નવીનભાઈ પટેલ</td>
                <td>શ્રીમતિ ડીમ્પલબેન દીપલભાઈ પટેલ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  કારોબારી સમિતિ, વસો
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી મયુરભાઇ નટુભાઇ અમીન</td>
                <td>શ્રી કંદર્પભાઈ કૃષ્ણકાંતભાઈ અમીન</td>
              </tr>
              <tr>
                <td>શ્રી જયમલભાઈ જગદીશભાઇ અમીન</td>
                <td>શ્રી પ્રશાંતભાઈ આનંદભાઈ અમીન</td>
              </tr>
              <tr>
                <td>શ્રી પાવનભાઈ ભુપેન્દ્રભાઈ પટેલ</td>
                <td>શ્રીમતિ કોકિલાબેન મહેન્દ્રભાઇ અમીન</td>
              </tr>
            </tbody>
          </table>

          <h3 className="fw-bold  text-center">છ ગામ પાટીદાર મંડળ, અમદાવાદ</h3>
          <h4 className="fw-bold  text-center">યુવાપાંખ ૨૦૨૩-૨૪</h4>
          <br />
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  પ્રમુખ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી રશેષ મધુભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  ઉપપ્રમુખ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી દેવેન્દ્રભાઈ જિતેન્દ્રભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રી શેતલ જયંતિભાઈ પટેલ</td>
                <td>નડીઆદ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  મંત્રી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી અલ્કેશ કિરીટભાઇ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  સહમંત્રી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી પરેશ ઘનશ્યામભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રી વિશાલ રજનીકાંત પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  ખજાનચી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી પરેશ રમણીકભાઈ પટેલ</td>
                <td>નડીઆદ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  ઓફિસ ઇન્ચાર્જ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી પંકજભાઈ રમેશભાઈ દેસાઇ</td>
                <td>નડીઆદ</td>
              </tr>
              <tr>
                <td>શ્રી પ્રિયવદન મહેન્દ્રભાઇ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  કારોબારી સભ્ય
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રી જનક રમણભાઈ પટેલ</td>
                <td>ધર્મજ</td>
              </tr>
              <tr>
                <td>શ્રી મનીષ જગદીશભાઇ પટેલ</td>
                <td>ધર્મજ</td>
              </tr>
              <tr>
                <td>શ્રી ઉત્પલ મહેન્દ્રભાઇ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
              <tr>
                <td>શ્રી રિપુલ દિલીપભાઇ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
              <tr>
                <td>શ્રી પરેશ મનુભાઈ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
              <tr>
                <td>શ્રી સત્ય ચેતનભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રી હર્ષવર્ધન વિહારીભાઇ પટેલ</td>
                <td>કરમસદ</td>
              </tr>
              <tr>
                <td>શ્રી જવલ દિનેશભાઇ પટેલ</td>
                <td>વસો</td>
              </tr>
              <tr>
                <td>શ્રી જય કૌશિકભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રી કૌશલ ભરતભાઇ પટેલ </td>
                <td>ધર્મજ</td>
              </tr>
              <tr>
                <td>શ્રી સ્નેહલ શૈલેષભાઇ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
            </tbody>
          </table>
          <h3 className="fw-bold  text-center">છ ગામ પાટીદાર મંડળ, અમદાવાદ</h3>
          <h4 className="fw-bold  text-center">મહિલા પાંખ ૨૦૨૩-૨૪</h4>
          <br />
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  ટ્રસ્ટીશ્રી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રીમતિ મિનલબેન ભુપેન્દ્રભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ જીનાબેન ચેતનભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  પ્રમુખ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રીમતિ જ્યોતિબેન સુરેશભાઇ પટેલ</td>
                <td>નડીઆદ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  ઉપપ્રમુખ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રીમતિ ફાલ્ગુનીબેન આગ્નેયભાઈ દેસાઇ</td>
                <td>નડીઆદ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ રીટાબેન અરૂણભાઈ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
              <tr>
                <td>શ્રીમતિ જયશ્રીબેન દિનેશભાઇ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ ગીતાબેન દિનેશભાઇ પટેલ</td>
                <td>વસો</td>
              </tr>
              <tr>
                <td>શ્રીમતિ પૂનમબેન હિમાંશુભાઈ પટેલ</td>
                <td>કરમસદ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ પન્નાબેન નિખિલભાઈ પટેલ</td>
                <td>ધર્મજ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  મંત્રી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રીમતિ રુપમબેન ભરતભાઇ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  સહમંત્રી
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રીમતિ કલ્પનાબેન ભરતભાઇ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
              <tr>
                <td>શ્રીમતિ પ્રીતિબેન ધર્મેશભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col" className="text-center" colSpan="2">
                  કારોબારી સભ્ય
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>શ્રીમતિ મિત્તલબેન પરેશભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ કુંજલબેન દેવેન્દ્રભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ પ્રીતિબેન યોગેશભાઈ પટેલ</td>
                <td>ભાદરણ</td>
              </tr>
              <tr>
                <td>શ્રીમતી સંગીતાબેન મનીષભાઈ પટેલ</td>
                <td>ધર્મજ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ રાજેશ્વરીબેન સમીરભાઈ પટેલ</td>
                <td>ધર્મજ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ ઉપેશાબેન મનીષભાઈ પટેલ</td>
                <td>કરમસદ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ પ્રજ્ઞાબેન પ્રવીણભાઈ પટેલ</td>
                <td>કરમસદ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ પ્રતિમાબેન જયપ્રકાશભાઈ પટેલ</td>
                <td>કરમસદ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ મેઘનાબેન પ્રશાંતભાઈ પટેલ</td>
                <td>નડીઆદ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ પૌલોમી ધ્રુમિલ પટેલ</td>
                <td>નડીઆદ</td>
              </tr>
              <tr>
                <td>શ્રીમતિ અદિતિબેન શેતલભાઈ પટેલ</td>
                <td>નડીઆદ</td>
              </tr>
              <tr>
                <td>શ્રીમતી મુગ્ધાબેન વિસમિતભાઇ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
              <tr>
                <td>શ્રીમતિ પિનલ હિમાંશુભાઈ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
              <tr>
                <td>શ્રીમતિ મયુરીબેન પ્રેમલભાઈ પટેલ</td>
                <td>સોજીત્રા</td>
              </tr>
              <tr>
                <td>શ્રીમતિ રાગિનીબેન રમેન્દ્રભાઈ પટેલ</td>
                <td>વસો</td>
              </tr>
              <tr>
                <td>શ્રીમતિ ફાલ્ગુનીબેન હિરેનભાઇ પટેલ</td>
                <td>વસો</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OfficeBearers;
