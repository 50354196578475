import React from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Header from "./components/Header";
import "./App.scss";
import Footer from "./components/Footer";
import Privacy from "./pages/Privacy";
import Patrika from "./pages/Patrika";
import OfficeBearers from "./pages/OfficeBearers";

function App() {
  const routers = [
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "privacy_policy",
      element: <Privacy />,
    },
    {
      path: "office_bearers",
      element: <OfficeBearers />,
    },
    {
      path: "patrika",
      element: <Patrika />,
    },
  ];
  return (
    <Routes>
      {routers.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={
              <>
                <Header />
                {route.element}
                <Footer />
              </>
            }
          />
        );
      })}
    </Routes>
  );
}

export default App;
