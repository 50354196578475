import LandingInfo from "../components/Landing/LandingInfo";
import LandingSlider from "../components/Landing/LandingSlider";

function Landing() {
  return (
    <div>
      <LandingSlider />
      <LandingInfo />
    </div>
  );
}

export default Landing;
