import IconPdf from "../../Icon/IconPdf";

function PdfCard(props) {
  return (
    <a
      href={`https://storage.strinix.com/cpm/patrika/${props.pdf}.pdf`}
      without
      rel="noreferrer"
      target="_blank"
    >
      <div className={`rounded-2 p-4  border text-center mb-4`}>
        <IconPdf width="100" height="100" />
        <h4 className="mt-2 mb-1">{props.month}</h4>
        <p className="text-body mb-0">{props.year}</p>
      </div>
    </a>
  );
}

export default PdfCard;
